.flexColumn {
  display: flex;
  flex-direction: column;
}
.setSideMenu {
  display: grid;
  grid-template-columns: 350px 1fr;
}
.sidebarComponent {
  background-color: #f6f0e8;
  position: fixed;
  top: 138px;
}
.offStyle{
  position:absolute;
  left: 12px;
top: 10px;
/* font-family: 'Sharp Grotesk Book 20'; */
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */


/* light grey */

color: #F0F0F0;
}
.headingPrice{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 31px;
/* identical to box height */


/* Theme-green */

color: #026D60;
}
.headingTL{


/* font-family: 'Sharp Grotesk Medium 20'; */
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */


/* B1 */

color: #333333;
}
.marginBottom16 {
  margin-bottom: 16px;
}
.marginBottom4 {
  margin-bottom: 4px;
}
.marginBottom8 {
  margin-bottom: 8px;
}

.marginBottom24 {
  margin-bottom: 24px;
}
.marginBottom32 {
  margin-bottom: 32px;
}
.marginBottom48 {
  margin-bottom: 48px;
}
.marginBottom20 {
  margin-bottom: 20px;
}
.marginBottom0 {
  margin-bottom: 0px;
}
.marginLeft12 {
  margin-left: 12px;
}
.marginLeft16 {
  margin-left: 16px;
}
.marginRight32 {
 margin-right: 32px;
}
.marginRight8 {
  margin-right: 8px;
 }
.marginLeftt32 {
  margin-left: 32px;
 }
.removeLine {
  text-decoration: none;
}
.showBanner{
  display:none;
}
.myAccountStracture{
  display: grid;
  grid-template-columns: 300px 1fr;
  column-gap: 24px;
}
.upcomingMainStracture{
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 48px;
}
.yBtn {
  background-color: #fbaf3f;
  border: 1px solid #2b302f;
  border-radius: 4px;
  width: 100%;
  padding: 12px 16px;
}
.pointer {
  cursor: pointer;
}
.wBtn {
  background: #ffffff;
  border: 1px solid #2b302f;
  border-radius: 4px;
  width: 100%;
  padding: 12px 16px;
}
.gBtn {
  background: #026d60;
  border: 1px solid #2b302f;
  border-radius: 4px;
  width: 100%;
  padding: 12px 16px;
  color: #FFFFFF;
}
.cancelBtn {
  font-family: "Sharpgroteskbook18, sans-serif";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  text-decoration-line: underline;

  /* Theme-green */

  color: #026d60;
}
.selected {
  color: #026d60 !important;
}
.p32 {
  padding: 32px;
}
.p24 {
  padding: 24px;
}
.p16 {
  padding: 16px;
}
.p14 {
  padding: 14px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb24 {
  padding-bottom: 24px;
}

.pb32 {
  padding-bottom: 32px;
}
.pl{
  padding-left: 32px;
}
.pr{
  padding-right: 32px;
}

.whiteBackground {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fbfbfb;
}
.planStracture {
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 24px;
}
.ADStracture {
  display: grid;
  grid-template-columns: 1fr 30px;
  column-gap: 24px;
}

.headingXS {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* B1 */

  color: #333333;
}
.headingXS2{
  font-family: 'Sharp Grotesk SmBold 20';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
/* identical to box height */


/* B1 */

color: #333333;
}
.headingXS3{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
/* identical to box height */


/* B1 */

color: #333333;
}
.headingSM {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* black */

  color: #2b302f;
}
.headingSM2{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

/* Theme-green */

color: #026D60;
}

.headingMD {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  /* black */

  color: #2b302f;
}
.headingMD2{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */


/* black */

color: #2B302F;

}
.headingMD3{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */


/* black */

color: #2B302F;
}
.headingLG {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: #2b302f;
}
.headingLG2{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 25px;
/* identical to box height */

text-align: center;

/* black */

color: #2B302F;
}
.headingXXL {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height */

  /* black */

  color: #2b302f;
}
.headingXXL2{
  font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 41px;
/* identical to box height */


/* black */

color: #2B302F;
}
.paraSM {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* B1 */

  color: #333333;
}
.paraSM2{
  /* font-family: 'Sharp Grotesk Book 20'; */
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* identical to box height */


/* grey */

color: #4E5554;
}
.paraMD {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-family: "Sharpgroteskbook18";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* B1 */

  color: #333333;
}
.paraMD2{
  /* font-family: 'Sharp Grotesk Book 20'; */
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */


/* grey */

color: #4E5554;
}
.paraLG {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  /* font-weight: 400; */
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;

  color: #026d60;
}
.paraLG2{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height, or 138% */

text-decoration-line: underline;

/* Theme-green */

color: #026D60;
}
.para500 {
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* B1 */

  color: #333333;
}
.para5002{
  /* font-family: 'Sharp Grotesk Medium 20'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
}
.dFlex{
  display: flex;
 
}
.shopNowBtn{
  /* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

text-align: center;

color: #026D60;
}
.UlStyle{
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}
.headingNav{
  
/* font-family: 'Sharp Grotesk SmBold 20'; */
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

/* B1 */

color: #333333;
}
.sb{
  display: flex;
  justify-content: space-between;
}
.shopNow{
  background: #EEFAF4;
border-radius: 4px;
}
.bannerCard{
  margin: 59px 8px 59px 32px;
}
.myAccMB{
  margin-bottom: 120px;
}
.VACmain{
  display: flex;
flex-direction: row;
align-items: center;
padding: 20px 32px;
gap: 10px;



/* Theme-light-2 */

background: #F0DFD2;
border-radius: 8px 8px 0px 0px;
}
.searchFriendParent{
display: grid;
grid-template-columns: 1fr 55px;
column-gap: 16px;
}
.friendSearchBar{
 width: 100%;
 background-color:#EFEFEF;
 border-radius: 4px;
 display: flex;
 align-items: center;
 padding: 11px 2px
 
}
.friendSearchBar  input{
  background-color:#EFEFEF;
  border: none;
  outline: 0px;
}
.listMain{
padding: 12px 0px 12px 16px;
background-color: #EFEFEF;
display: grid;
grid-template-columns: 100px 1fr;
column-gap: 0px;
}
.ordersStracture {
  display: grid;
  grid-template-columns: 100% ;
  column-gap: 8px;
}
.fixOrder{
  width: 1200px;
}
.fixShop{
  width: 910px;
}
.fixShopCard{
  width: 910px;
  /* width: 1000px; */
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.greenBtn{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 16px;

width: 155px;
height: 44px;
color: #F0F0F0;
/* Theme-green */

background: #026D60;
border: 1px solid #2B302F;
border-radius: 4px;
}
.styleLabel {
  /* font-family: 'Sharp Grotesk Book 20'; */
  /* font-family: "Sharpgroteskbook18, sans-serif"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  /* identical to box height, or 157% */

  /* B1 */

  color: #333333;
}
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: #026d60;
  margin-left: 8px;
  margin-right: 8px;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
/* input[type="radio"]:checked:after {
  width: 6px;
  height: 6px;
  border-radius: 15px;
  top: -4px;
  left: 1px;
  position: relative;
  background-color: #026d60;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */
.disabled{
  /* font-family: 'Sharp Grotesk Book 20'; */
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */


/* Disable-color */

color: #A6A6A6;
}
.notAvailable{
  /* font-family: 'Sharp Grotesk Book 20'; */
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 22px;
/* identical to box height, or 169% */


/* Theme-red */

color: #A84726;
}
.accountStracture {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
.viewAllStracture {
  display: grid;
  grid-template-columns: 40px 1fr 110px;
  column-gap: 50px;
  row-gap: 32px;
}
.RStracture{
  display: grid;
  grid-template-rows: auto;
}
.BannerTop{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%,
}
.RSRowOne{
  display: grid;
  grid-template-columns: 300px 1fr;
}
.RefferalsMain{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
.friendsStracture{
  display: grid;
  grid-template-columns: 1fr 50px;
  column-gap: 8px;
  /* border-bottom: 1px solid #EFEFEF;
  margin-bottom: 32px; */
}
@media screen and (max-width: 991px) {
  .setSideMenu {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
}
@media screen and (max-width: 767px) {
  .setSideMenu {
    display: grid;
    grid-template-columns: 180px 1fr;
  }
}
@media screen and (max-width: 479px) {
  .fixShopCard{
    width: 1000px;
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .fixShop{
    width: 100%;
  }
  .fixOrder{
    width: 100%;
  }
  .myAccMB{
    margin-bottom: 0px;
  }
  .OrderStracture{
margin-top: 2rem;
  }
  .setSideMenu {
    display: grid;
    grid-template-columns: 0px 1fr;
  }
  .sidebarComponent {
    display: none;
  }
  .accountStracture {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
  }
  
  .dFlex{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 8px;
  }
  .myAccountStracture{
    display: grid;
    grid-template-columns:  1fr;
    /* column-gap: 24px; */
  }
  .RStractureMb{
    margin-top: 2rem;
    /* margin-left: 6rem; */
  }
  .RefferalsMainMB{
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 50px;
  }
  .viewStractureMB{
    margin-top: 4rem;
 
  }
  .upcomingMainStractureMB{
    display: grid;
  grid-template-columns: 100%
  }
  .accountStractureMBA{
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 8px;
    padding-top: 0px;
    margin-top: 0px;
  }
  .VMB{
    padding-left: 32px;
  }
  .child1{
    order: 2;
  }
  .child2{
order: 1;
  }
  .child3{
    order: 3;
  }
  .accountStractureMB{
    display: grid;
    grid-template-columns: 100%
  }
  .p5MB{
    padding-top: 5px;
  }
  .RSRowOneMB{
    display: grid;
    grid-template-columns: 1fr
  }
  .showBanner{
    display:flex;
  }
  .BannerTopMB{
    
    display: none;
    width: max-content;
    /* grid-template-columns: 1fr */
  }
  .referralStractureMB{
    margin-top: 5rem;
    overflow-x: auto;
   
  }
  .btnViewMb{
    display: flex;
    align-items: center;
    padding-left: 30px;
  }
}
