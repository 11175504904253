code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.overflow-hidden {
  /* overflow: hidden; */
}

.mobile-header-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.faq-desc-container {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-row-header.active ~ .faq-desc-container {
  max-height: unset;
}

.faq-row-header.active .faq-icon-container {
  -webkit-transform: rotate( -45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate( -45deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.faq-container .faq-row:last-of-type {
  border-bottom: none !Important;
}

.faq-row-header.active .faq-row-title {
  font-family: 'Sharp grotesk 20', sans-serif;
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 7px;
  height: 7px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #3898ec;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #dbfc3e;
}

.input__absolute_img.is-mob.is-pass {
  width: 32px;
}
.input__absolute_img.is-mob.is-pass.is--showhide {
  left: auto;
  right: 20px;
}

.pass-strength-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pass-strength-pill.is--active {
  position: relative;
  background-color: #026d60;
}

.pass-strength-pill {
  width: 40px;
  height: 10px;
  margin-top: -2px;
  margin-right: 10px;
  border-radius: 100px;
  background-color: #c7c7c7;
}

@media screen and (max-width: 479px){
.pass-strength-wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
}
 
.otpbox {
  width: 22%;
  height: 50px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: #e6e6e6;
}

.registerotp-iinner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}


.slick-slide,
.slick-slide,
.slick-slide{
  margin: 0 15px;
}
/* the parent */
.slick-list,
.slick-list,
.slick-list,
.slick-list {
  margin: 0 -15px;
}

.slick-track{
  display:flex !important;
  }

  .slick-next, .slick-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgb(51 60 63 / 22%);
    z-index: 11;
}

.slick-prev {
  left: -25px;
}

.slick-next, .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

.slick-next:before, .slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
  background-image: url(https://assets.website-files.com/5fa3b25…/603502b…_left_Arrow.svg);
  background-size: 18px 30px;
  display: inline-block;
  width: 18px;
  height: 30px;
  content: "";
}

.cat__gridcard {
  position: relative;
  display: flex !important;
  width: 180px !important;
  height: 200px;
  padding: 20px;
  flex-direction: column !important;
  justify-content: space-around !important;
  align-items: center !important;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 20px 0 rgb(0 0 0 / 13%);
  align-content: flex-start;
}
.cat__slider-wrap{
  overflow: hidden;
}

.cat__slider-wrap > .cat__gird > .slick-slider > button {
  display: none !important;
}
.mobile__header > .w-embed > .mobile__navigation-btn .mobile__menu-line .is--top{
  color: black !important;
}