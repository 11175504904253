.overflow-hidden {
	/* overflow: hidden; */
}

.mobile-header-active {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

.et-modal.et-modal-active {
	opacity: 1;
	display: flex;
	transition: opacity 300ms ease 0s;
}

.et-modal-container {
	transform: scale(0);
	transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.et-modal-active .et-modal-container {
	transform: scale(1);
	transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

body.modal-open, body.drawer-open {
	height: 100vh;
	overflow-y: hidden;
	padding-right: 15px;
}

.modal-content-wrap, .drawer-content-wrap {
	-webkit-animation-duration: .85s;
	animation-duration: .85s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: bounceInUp;
	animation-name: bounceInUp;
}

@keyframes bounceInUp {
	0%, 30%, 60%, 80%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 200px, 0);
		transform: translate3d(0, 200px, 0);
	}
	30% {
		opacity: 1;
		-webkit-transform: translate3d(0, -40px, 0);
		transform: translate3d(0, -40px, 0);
	}
	60% {
		-webkit-transform: translate3d(0, 4px, 0);
		transform: translate3d(0, 4px, 0);
	}
	80% {
		-webkit-transform: translate3d(0, -2px, 0);
		transform: translate3d(0, -2px, 0);
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}