/* Custom CSS */

@media screen and (max-width: 767px) {
  .testimonial__grid {
    padding-bottom: 30px;
  }

  .headercta.is--outline.strip-btn.is--blackoutlne {
    width: 100%;
  }

  .faq__right .faq__content-section:last-of-type {
    margin-bottom: 0;
  }

  .stillquestion {
    padding-top: 0;
  }

  .section.section-contacthero {
    padding-top: 120px;
  }
}

@media screen and (max-width: 479px) {
  .faq__left.is--sc {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .footer__copylink {
    font-size: 12px;
    margin-top: 10px;
  }

  .double_tick {
    width: 25px;
  }

  .inter__top {
    top: -50px;
  }

  .section.intersting-fact {
    margin-top: 50px;
  }

  .register--progress-wrap {
    overflow: auto;
  }

  .insta__container {
    max-width: 100%;
    width: 100%;
  }

  .insta-top-left,
  .insta-top-middle {
    height: 100px;
  }

  .ourstory_content-grid {
    width: 100%;
    max-width: 100%;
  }
  .insta__logo {
    width: 26px;
  }

  .insta__toprow {
    margin-bottom: 20px;
  }

  .headerlogo__text {
    /* color: white; */
    color: #026d60;
  }

  .w-useemail {
    display: flex;
    flex-direction: column;
  }

  .w-useemail .input__absolute_img.is-mob {
    top: 38%;
  }
  .w-useemail .user-email {
    padding-top: 5px;
    position: relative;
    top: unset;
    transform: unset;
  }

  .page--sourcing .section.section-header.sectio-green,
  .page--faq .is--faq,
  .page--contact .section.section-header.sectio-green {
    padding-top: 110px;
  }

  .page--contact .section.section-header.sectio-green {
    padding-bottom: 120px;
  }

  .w-useemail .user-email {
    margin-left: 10px;
  }

  .page--faq .section.section-header.sectio-green.is--faq.is-fa {
    padding-top: 113px !important;
    padding-bottom: 80px;
  }

  .wf-section .section_title-container.is-w80 {
    width: 100%;
    max-width: 100%;
  }
}

.is--green .input__absolute_img {
  filter: invert(1);
}

.page--ourstory .mobile__header .headerlogo__text,
.page--register .mobile__header .headerlogo__text {
  color: #026d60;
}

.page--sourcing .mobile__header .headerlogo__text,
.page--contact .mobile__header .headerlogo__text {
  color: #000;
}
/* changed */
.page--home .mobile__header .mobile__menu-line,
.page--faq .mobile__header .mobile__menu-line {
  /* filter: invert(1); */
}

.page--ourstory .ctastrip {
  background-color: rgb(248, 125, 80);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.emptyCartHeading {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4e5554;
}
.emptyCartPara {
  font-family: "Sharp Grotesk Book 20";
  font-size: 14px;
  line-height: 22px;

  color: #4e5554;
}
.emptyCartEditPara {
  font-family: "Sharp Grotesk Book 20";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  width: 308px;
}
.emptyCartDeliveryPara {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  color: #333333;
}
.emptyCartBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 84px;
  gap: 10px;
  width: -webkit-fill-available;
  width: 100%;

  background: #fbaf3f;

  border: 1px solid #2b302f;
  border-radius: 4px;
}
.cartBottomFAQ {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* B1 */

  color: #333333;
}
.cartBottomCheckOut {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* B1 */

  color: #333333;
}
.cartBottomPara {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* B1 */

  color: #333333;
}

.cartReadyToShip {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.containerExtra {
  position: fixed;
  top: 0;
  right: 0;
  width: 70vh;
  background-color: #f0f0f0;
  z-index: 1;
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  height: -webkit-fill-available;
}
.container {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.cartProduct {
  overflow-y: scroll;
}

.cartProduct::-webkit-scrollbar {
  width: 4px;
}

.cartProduct::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.cartProduct::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
  border-radius: 10px;
}

.cartSubLabel {
  font-family: "Sharp Grotesk Book 20";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  color: #333333;
}
.cartGroc {
  font-family: "Sharp Grotesk SmBold 20";
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  /* identical to box height, or 150% */

  /* B1 */

  color: #333333;
}

.Incomplete {
  font-family: "Sharp Grotesk Medium 20";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  margin-bottom: 0px;
  /* identical to box height, or 167% */

  color: #ffdede;
}
.ReadyTo {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0px;
  /* identical to box height, or 167% */

  color: #026459;
}
.addGro {
  font-family: "Sharp Grotesk Book 20";
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;

  /* B1 */

  color: #333333;
}
.cartReadyText {
  font-family: "Sharp Grotesk Book 20";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  /* B1 */

  color: #333333;
}
.topST {
  font-family: "Sharp Grotesk Book 20";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.01em;
  margin-bottom: 0px;
  margin-right: 4rem;
  /* grey */

  color: #4e5554;
}
.cartProductPrice {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* grey */

  color: #4e5554;
}
.cartTitle {
  font-family: "Sharp Grotesk SmBold 20";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* B1 */

  color: #333333;
}
.productDetail {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* grey */

  color: #4e5554;
}
.cartCount {
  font-family: "Sharp Grotesk Book 20";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* B1 */

  color: #333333;
}


